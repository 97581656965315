import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '10px',
        color: theme.palette.primary.main,
    },
    dayInputContainer:{
        display: 'grid',
        gridTemplateColumns: '49fr 1fr',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
        minHeight: '50px',
        paddingTop: '5px'
    },
    dayContainer:{
        display: 'grid',
        gridTemplateColumns: '2fr 38fr',
        justifyContent: 'start',
        alignItems: 'start',
        gap: '15px',
        width: '100%',
        minHeight: '50px'
    },
    workingHourInputs:{
        display: 'grid',
        gridTemplateColumns: '5fr 5fr 15fr 1fr',
        justifyContent: 'start',
        alignItems: 'start',
        gap: '10px',
        width: '100%',
        minHeight: '50px'
    },
    // TODO: LINE STYLE BETWEEN CONTAINERS
    workingDaysContainer:{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '5px',
        '& > div': {
            borderTop: `0.01rem solid ${theme.palette.gray.light}`
        }

    },
    UnavailabilityContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '10px',
        '& > Button':{
            backgroundColor: theme.palette.primary.main,
            '&:hover':{
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    UnavailabilityFormContainer: {
        fontSize: '14px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '10px',
    },
    UnavailableItem: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '18fr 1fr 1fr',
        gap: '10px',
    },
    UnavailableBadges: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        width: '100%'

    },
    UnavailableBadge: {
        display: 'flex',
        borderRadius: '2px',
        padding: '5px',
        fontSize: '0.85rem',
        backgroundColor: '#e6e6e6',
        justifyContent: 'center',
        alignItems: 'center'
    },
    siteSettingsContainer:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    timePickersContainer:{
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '5px',
    },
    calendarConfigContainer:{
        paddingTop: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '75px'
    },
    ActionDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > Button':{
            backgroundColor: theme.palette.primary.main,
            '&:hover':{
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    icon: {
        color: '#F6BA3B',
        fontSize: '45px',
        marginRight: theme.spacing(1),
    },
    mainHeading: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
        fontSize: '20px'
    },

    generalText: {
        color: 'black',
        fontSize: '14px',
        marginRight: theme.spacing(2),
    },

    emphasizedText: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    advancedSettings: {
        display:'flex',
        width:'fit-content',
        justifyContent:'center',
        '& > Button':{
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: 'transparent',
            '&:hover':{
                backgroundColor: 'transparent'
            }
        }
    },
    dialogPaper: {
        minHeight: '80vh',
        minWidth: '80vw',
        maxHeight: '80vh',
    },
    dialogTile:{
        display:'flex',
        flexDirection: 'column',
        gap: '5px',
        color: theme.palette.primary.main
    },
    dialogContent: {
        display:'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    dialogAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > Button':{
            backgroundColor: theme.palette.primary.main,
            '&:hover':{
                backgroundColor: theme.palette.primary.main
            }
        },
        paddingTop:'20px',
        paddingBottom:'20px',
    },
    EventTypes: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        gap: '50px',

    },
    EventTypeViewContainer:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginLeft: '15px',
        borderRadius: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        paddingBottom: '10px',
        '& > Button':{
            width: 'fit-content',
            backgroundColor: theme.palette.primary.main,
            alignSelf: 'center',
            '&:hover':{
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    EventTypeName:{
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        padding: '15px',
        borderRadius: '10px 10px 0px 0px'
    },
    EventTypeServices:{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        color: 'black',
        listStyle: 'none',
    },
    EventTypeAttributes:{
        display:'flex',
        flexDirection: 'row',
        gap:'5px',
        flexWrap: 'wrap',
        marginLeft: '20px'

    },
    EventTypeAttribute:{
        width: 'fit-content',
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: theme.palette.gray.main,
        color: 'white'
    },
    NewEventTypeContainer:{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }


}));

export default useStyles;
