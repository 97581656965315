import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

export const StatusDetails = ({
    status,
    comments,
    other_comments,
    badgeColor
}) => {
    const classes = useStyles();
    return (
        <Tooltip
            title={
                <div className={classes.tool_tip_div}>
                    <div>
                        <b>Co-morbidity risk stratification:</b>
                    </div>
                    <div>
                        <b>{status}</b>
                    </div>
                    {
                        <ul style={{ paddingInlineStart: '15px' }}>
                            {[...comments, ...other_comments].map(
                                (comment, idx) => {
                                    return <li key={idx}>{comment}</li>;
                                }
                            )}
                        </ul>
                    }
                </div>
            }
            className={classes.conditionTooltip}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: '#000d1a',
                        boxShadow: '2px 2px 2px 3px rgba(0, 0, 0, 0.1)',
                        fontSize: '0.8rem',
                    },
                },
            }}
        >
            <div
                className={classes.co_morbidity_badge}
                style={{ backgroundColor: badgeColor }}
            >
                {status}
            </div>
        </Tooltip>
    );
};

const useStyles = makeStyles((theme) => ({
    co_morbidity_badge: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '15px',
        width: 'fit-content',
        height: 'fit-content',
        fontSize: '0.8rem',
        color: 'white',
        textAlign: 'center',
        alignItems: 'center',
        padding: '10px',
        paddingBottom: '5px',
        paddingTop: '5px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tool_tip_div: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
