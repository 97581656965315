//Source: https://codesandbox.io/s/laughing-liskov-3e66h6?file=/Wizard.js:0-2337
import React, { useEffect, useState } from 'react';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import FormPage from './FormPage';

const MultiPageForm = ({
    children,
    handleSubmit,
    internalSubmit,
    prePopulate,
    prePopulatePageIndex,
    initialValues,
    setInitialValues,
    values,
    valid,
    submitting,
    pageList,
    setPageList,
    getUpdatedPages,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const nextPage = (values) => {
        setInitialValues(values);
        setCurrentPageIndex(Math.min(currentPageIndex + 1, pageList.length));
    };

    const prevPage = (values) => {
        setCurrentPageIndex(Math.max(currentPageIndex - 1, 0));
    };

    const innerHandleSubmit = (event) => {
        const sendSMS = event?.nativeEvent?.submitter?.id === "send_sms"
        if (valid) {
            if (currentPageIndex in prePopulatePageIndex) {
                prePopulate[currentPageIndex](values);
            }
            if (currentPageIndex < pageList.length - 1) {
                event.preventDefault();
                nextPage(values);
            } else {
                event.preventDefault();
                internalSubmit({...values, send_sms: sendSMS});
            }
        } else {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (React.Children.toArray(children).length !== pageList.length)
            console.warn(
                'FormStepper will not work. Number of pages in pagelist that was provided does not match number of pages(i.e. children)'
            );
    }, [pageList]);

    useEffect(() => {
        const newPageList = getUpdatedPages(values);
        if (pageList !== newPageList) setPageList(newPageList);
    }, [values]);

    return (
        <div style={{ width: '95%', padding: '15px' }}>
            <Stepper style={{ paddingBottom: '20px' }} activeStep={currentPageIndex}>
                {pageList.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <form onSubmit={innerHandleSubmit}>
                {React.Children.toArray(children)[currentPageIndex].type === FormPage
                    ? React.Children.toArray(children)[currentPageIndex]
                    : undefined}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {currentPageIndex > 0 ? (
                        <Button
                            style={{ margin: '5px' }}
                            key="cancel"
                            disabled={submitting}
                            type="button"
                            variant="outlined"
                            onClick={() => {
                                prevPage(values);
                            }}
                        >
                            Previous
                        </Button>
                    ) : undefined}
                    {currentPageIndex === pageList.length - 1 ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                style={{ margin: '5px' }}
                                disabled={submitting}
                                variant="outlined"
                                type="submit"
                                >
                                    Proceed without consent sms
                                </Button>
                                <Button
                                    id={"send_sms"}
                                    style={{ margin: '5px' }}
                                    disabled={submitting}
                                    variant="contained"
                                    type="submit"
                                    color={'primary'}
                                >
                                    Proceed
                                </Button>
                            </div>

                            ) : (
                            <Button
                                style={{ margin: '5px' }}
                                disabled={submitting}
                                variant="contained"
                                type="submit"
                                color={'primary'}
                            >
                                Next
                            </Button>
                            )}
                        </div>
                        </form>
                        </div>
                        );
                    };
                    export default MultiPageForm;
//Problem: the children keep on changing
