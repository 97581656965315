import useStyles from '../../styles';
import React from 'react';
import WorkingHours from '../../WorkingHours';
import Unavailability from '../../Unavailability';
import SiteSettings from '../../SiteSettings';
import { Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const CalendarConfigForm = ({
    handleSubmit,
    form: {
        mutators: { push, pop },
    }, // injected from final-form-arrays above
    dirty,
    form,
    submitting,
    values,
    errors,
    setViewAdvanced,
    eventTypes,
    initialValues,
    disableSave
}) => {
    const classes = useStyles();
    const { mutators } = form;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form style={{ width: '100%', fontFamily: 'sans-serif', marginTop: '5px' }} onSubmit={handleSubmit}>
            <div className={classes.ActionDiv} style={{ position: 'absolute', top:'50px', right: '30px'}}>
                {/*{dirty && <div>{errors.general}</div>}*/}

                <div className={classes.advancedSettings}>
                    <Button
                        onClick={() => {
                            setViewAdvanced(true);
                        }}
                        style={{ margin: '5px' }}
                        variant="contained"
                        color={'primary'}
                    >
                        Advanced settings
                    </Button>
                </div>
                <Button
                    // onClick={({ }) => {
                    //     console.log(values);
                    // }}
                    type={'submit'}
                    disabled={disableSave}
                    style={{ margin: '5px' }}
                    variant="contained"
                    color={'primary'}
                >
                    Save
                </Button>
            </div>
            <div className={classes.calendarConfigContainer}>
                <WorkingHours mutators={mutators} eventTypes={eventTypes} />
                <div style={{display: 'flex', flexDirection: 'column', gap: '50px'}}>
                    <SiteSettings />
                    <Unavailability mutators={mutators} eventTypes={eventTypes} />
                </div>
            </div>

        </form>
        </LocalizationProvider>
    );
};

export default CalendarConfigForm;
