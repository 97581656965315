import React, { useRef, useState, useEffect } from 'react';
import FieldLayout from './FieldLayout';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import 'filepond/dist/filepond.min.css';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { getPresignedUrl } from '../../Apis/Procedure';
import UppyDashboard from '@a2d24/uppy';
import { UploadedDisplay } from './UploadedDisplay';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    heading: {
        color: 'rgba(0, 0, 0, 0.54)',
        whiteSpace: 'break-spaces',
        paddingBottom: '5px',
        fontSize: '1rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        lineHeight: '1.3em',
        letterSpacing: '0.01em',
        overflow: 'visible',
    },
}));

const UppyUploadField = ({
    id,
    name,
    optional,
    upload_config,
    initial,
    description,
    warning,
    ...rest
}) => {
    const config = upload_config || {};
    const { multi = false, max = 100, doc_types = [], has_title = false } = config;
    let label = name;
    // Max file size in MB
    const maxFileSize = 5;
    return (
        <>
            <FieldLayout description={description} warning={warning}>
                <Field
                    id={id}
                    name={id}
                    placeholder={label}
                    optional={optional}
                    initial={initial}
                    component={UppyUploadAdapter}
                    config={config}
                    maxFileSize={maxFileSize}
                ></Field>
            </FieldLayout>
        </>
    );
};
export default UppyUploadField;
// Proposal: create a separate component for displaying files that have already been uploaded, and combine this with current values when submitting the form

const UppyUploadAdapter = ({initial, id, input, optional, placeholder, config, maxFileSize, ...rest }) => {
    const classes = useStyles();
    const { onChange, ...restInput } = input;
    const [value, setValue] = useState([]);
    const [alreadyUploads, setAlreadyUploads] = useState(initial? initial: []);
    const [files, setFiles] = useState({});
    // const [numFiles, setNumFiles] = useState(initial?initial.length: 0);
    const location = useLocation();
    const location_arr = location.pathname.split('/');
    const procedure_id = location_arr.includes('procedure')
        ? location_arr[location_arr.length - 1]
        : '';
    const consult_id_idx = location_arr.indexOf('consults');
    const consult_id = location_arr[consult_id_idx + 1];

    const setFileValues = (filesObject) => {
        setValue(
            Object.values(filesObject).map((fileValue) => {
                return {
                    document_type: fileValue?.document_type,
                    document_title: fileValue?.document_title,
                    document_upload: fileValue?.key,
                    original_name: (fileValue.fileName ?? fileValue.name) || fileValue?.key,
                };
            })
        );
    };
    const onAfterUpload = async (file, plugin) => {
        plugin.uppy.emit('postprocess-progress', file, {
            mode: 'indeterminate',
            message: 'Adding metadata',
        });
        plugin.uppy.setFileState(file.id, {
            meta: {
                ...file.meta,
            },
        });
    };

    React.useEffect(() => {
        const full_value = [...alreadyUploads, ...value]
        onChange(full_value === '' ? null : full_value);
    }, [alreadyUploads, value]);

    React.useEffect(() => {
        setFileValues(files);
    }, [files]);

    const metaFields = [];
    // Add meta fields based on config
    if ((config.doc_types ?? []).length > 0) {
        metaFields.push({
            id: 'document_type',
            name: 'Document Type',
            render: ({ value, onChange }, h) => {
                return h(
                    'select',
                    { value, onChange: (e) => onChange(e.target.value) },
                    config.doc_types.map((option) =>
                        h('option', { value: option, selected: option === value }, option)
                    )
                );
            },
        });
    }
    if (config?.has_title) {
        metaFields.push({
            id: 'document_title',
            name: 'Document Title',
            placeholder: 'Please enter the title of the document',
        });
    }
    return (
        <>
            <InputLabel className={classes.heading} shrink={true}>
                {placeholder}
            </InputLabel>
            {
                (config.max??1) !== alreadyUploads.length?
                <UppyDashboard
                    initial={initial}
                    s3PreSignAdapter={(file) => {
                        return getPresignedUrl(
                            consult_id,
                            // Use location hook to get procedure when plugging it into dynamic form
                            procedure_id,
                            file.type,
                            file.extension
                        );
                    }}
                    onUploadedFilesChanged={(files) => {
                        const newFiles = Object.keys(files).reduce(
                            (prev, key) => ({
                                ...prev,
                                [key]: files[key].meta,
                            }),
                            {}
                        );
                        setFiles(newFiles);
                    }}
                    plugins={[]}
                    height="calc(85vh - 375px)"
                    width={'100%'}
                    onAfterUpload={onAfterUpload}
                    showRemoveButtonAfterComplete={true}
                    allowMultipleUploads={false}
                    options={{
                        autoProceed: metaFields.length===0,
                        restrictions: {
                            maxFileSize: maxFileSize * 1024 * 1024,
                            allowedFileTypes: ['image/*', 'application/pdf'],
                            maxNumberOfFiles: config.multi ? (config.max? config.max-alreadyUploads.length: 1) : 1,
                        },
                    }}
                    metaFields={metaFields}
                    autoOpenFileEditor={true}
                    hideProgressAfterFinish={true}
                    maxFileSize={maxFileSize}
                />
                :null
            }
            {
                alreadyUploads && alreadyUploads.length>0?
                <UploadedDisplay
                  uploads={alreadyUploads}
                  maxReached={(config.max??1) === alreadyUploads.length}
                  title={"Uploaded documents"}
                  onRemove={(uploadToRemove)=>{
                      setAlreadyUploads(
                        alreadyUploads.filter(upload=> upload.document_upload !== uploadToRemove)
                      )
                  }}
                />
                  :
                null
            }
            {
                // Hack - does not support required
                <input
                    testid={id}
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    required={!optional}
                    value={[...alreadyUploads, ...value]}
                    // required={!optional || numFiles>0}
                    // onInvalid={(e)=>{
                    //     // console.log(e);
                    //     e.target.setCustomValidity("Please upload files");
                    // }}
                    // onvalid={(e)=>{
                    //     console.log(e);
                    //     e.target.setCustomValidity("");
                    // }}

                    // readOnly
                />
            }
        </>
    );
};
