import React, { useEffect, useState, useContext, createContext } from 'react';
import { useParams } from 'react-router-dom';
import Recycle, { getObjectByPath } from '@a2d24/care-at-work-core/Recycle';
import { AppConfigContext } from '../App';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import makeStyles from '@mui/styles/makeStyles';

import BookingsTable from './BookingsTable';
import BookingDetails from './BookingDetails';
import Paper, { PaperContainer } from './Paper';
import Row from './Row';
import Column from './Column';
import Divider from './Divider';
import DynamicForm from './DynamicForm';
import PatientSearch from './PatientSearch';
import RegisterPatientConsent from './RegisterPatientConsent';
import ConsultsTable from './ConsultsTable';
import ConsultDetails from './ConsultDetails';
import ConsultProcedureDetails from './ConsultProcedureDetails';
import ProcedureDetails from './ProcedureDetails/ProcedureDetails';
import PatientHistoryTable from './PatientHistoryTable';
import SignoffBanner from './SignoffComponents/SignoffBanner';
import ClinicalDocumentsTable from './ClinicalDocumentTable';
import ConsultCompleteDocumentTable from './ConsultCompleteDocumentTable';
import Signoff from './SignoffComponents/Signoff';
import MetricGroup from './Metric';
import ConsultResult from './ConsultResult';
import Greetings from './Greetings';
import ConsultSuccessCard from './ConsultSuccessCard';
import ConsultsSearch from './ConsultsSearch';
import ConsultSearchResultsTable from './ConsultSearchResultsTable';
import OverrideStatus from './OverrideStatus';
import Redirect from './Redirect';
import Conditional from './Conditional';
import ConsultInfoPanel from './ConsultInfoPanel';
import ManualBookingButton from './ManualBookingButton';
import ManualBooking from './ManualBooking';
import StockManagementMenu from './StockManagement/StockManagementMenu';
import ReceiveSupplierStockBase from './StockManagementBase/ReceiveSupplierStockBase';
import StockTakeBase from './StockManagementBase/StockTakeBase';

import TransferStockToAnotherClinicBase from './StockManagementBase/TransferStockToAnotherClinicBase';
import TransferStockInFromAnotherClinicBase from './StockManagementBase/TransferStockInFromAnotherClinicBase';
import AdjustStockBase from './StockManagementBase/AdjustStockBase';
import MedicationAndConsumablesTable from './StockManagement/MedicationAndConsumablesTable';
import DiseaseManagementTable from './DiseaseManagementTable';
// Agenda
import AgendaSearch from './Agenda/AgendaSearch';
import AgendaEvents from './Agenda/AgendaEvents';
import ChronicConditionsEnrollment from './Agenda/ChronicConditionsEnrollment';
import AgendaConsultDetails from './Agenda/AgendaConsultDetails';
import AgendaConsultLogic from './Agenda/AgendaConsultLogic';
import AgendaPatientDetails from './Agenda/AgendaPatientDetails';
import RecommendedMedicalsTable from './RecommendedMedicalsTable';
import DiseaseManagementResult from './DiseaseManagementResult';
import CareActivitiesTimeline from './CareActivitiesTimeline';
// import WorkingHours from '@a2d24/calendar-config/widgets/WorkingHours';
// import SiteSettings from '@a2d24/calendar-config/widgets/SiteSettings';
// import Unavailability from '@a2d24/calendar-config/widgets/Unavailability';
import CalendarConfig from '@a2d24/calendar-config/widgets/CalendarConfig';
import AppointmentCalendarTable from './AppointmentCalendarTable';

export const widgets = {};
const registerWidget = (name, widget) => {
    widgets[name] = widget;
};

registerWidget('appointments-table', BookingsTable);
registerWidget('booking-details', BookingDetails);
registerWidget('paper', Paper);
registerWidget('paper-container', PaperContainer);
registerWidget('row', Row);
registerWidget('column', Column);
registerWidget('divider', Divider);
registerWidget('dynamic-form', DynamicForm);
registerWidget('patient-search', PatientSearch);
registerWidget('register-patient-consent', RegisterPatientConsent);
registerWidget('consults-table', ConsultsTable);
registerWidget('consult-details', ConsultDetails);
registerWidget('consult-procedure-details', ConsultProcedureDetails);
registerWidget('procedure-details', ProcedureDetails);
registerWidget('patient-history-table', PatientHistoryTable);
registerWidget('signoff-banner', SignoffBanner);
registerWidget('signoff', Signoff);
registerWidget('clinical-document-table', ClinicalDocumentsTable);
registerWidget('consult-complete-document-table', ConsultCompleteDocumentTable);
registerWidget('metric-group', MetricGroup);
registerWidget('consult-result', ConsultResult);
registerWidget('disease-management-result', DiseaseManagementResult);
registerWidget('greetings', Greetings);
registerWidget('consult-success-card', ConsultSuccessCard);
registerWidget('consults-search', ConsultsSearch);
registerWidget('consult-search-results-table', ConsultSearchResultsTable);
registerWidget('override-status', OverrideStatus);
registerWidget('redirect', Redirect);
registerWidget('conditional', Conditional);
registerWidget('consult-info-panel', ConsultInfoPanel);
registerWidget('manual-booking-button', ManualBookingButton);
registerWidget('manual-booking-form', ManualBooking);
registerWidget('stock-management', StockManagementMenu);
registerWidget('medication-consumables-table', MedicationAndConsumablesTable);
registerWidget('receive-supplier-stock', ReceiveSupplierStockBase);
registerWidget('take-stock', StockTakeBase);
registerWidget('transfer-stock-to-another-clinic', TransferStockToAnotherClinicBase);
registerWidget('transfer-stock-in-from-another-clinic', TransferStockInFromAnotherClinicBase);
registerWidget('adjust-stock', AdjustStockBase);
registerWidget('agenda-search', AgendaSearch);
registerWidget('agenda-events', AgendaEvents);
registerWidget('chronic-conditions-enrollment', ChronicConditionsEnrollment);
registerWidget('agenda-consult-details', AgendaConsultDetails);
registerWidget('agenda-consult-logic', AgendaConsultLogic);
registerWidget('agenda-patient-details', AgendaPatientDetails);

registerWidget('recommended-medicals-table', RecommendedMedicalsTable);
registerWidget('disease-management-table', DiseaseManagementTable);
registerWidget('care-activities-timeline', CareActivitiesTimeline);

registerWidget('calendar-config', CalendarConfig);
registerWidget('appointment-calendar-table', AppointmentCalendarTable);


export const WidgetContext = createContext({});

const Widget = React.memo(({ config, props, style={} }) => {
    const pathParams = useParams();
    const [widgetProps, setWidgetProps] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    useEffect(() => {
        initialize(true);

        if (config.data_poll_in_seconds) {
            const interval = setInterval(() => {
                refresh();
            }, config.data_poll_in_seconds);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    useEffect(() => {
        if (widgetProps) {
            setInitialized(true);
        }
    }, [widgetProps]);

    const initialize = async (showLoader = false) => {
        const data = await getData(showLoader);

        const newProps = {};

        config.props.forEach((prop) => {
            const propType = prop.type || prop.propType;

            if (propType && propType === 'appConfig') {
                newProps[prop.dest] = getObjectByPath(prop.source, appConfig);
            } else if (propType && propType === 'pathParam') {
                newProps[prop.dest] = pathParams[prop.pathKey];
            } else if (propType && propType === 'static') {
                newProps[prop.dest] = prop.value;
            } else {
                newProps[prop.dest] = getObjectByPath(prop.source, { ...props, ...data });
            }
        });

        setWidgetProps(newProps);
    };

    const getData = async (showLoader) => {
        let result = {};

        if (config.data) {
            await Promise.all(
                config.data.map(async (source) => {
                    // const args = [];
                    const kwargs = {};
                    if (source.args) {
                        source.args.forEach((arg) => {
                            if (arg.type === 'static') {
                                kwargs[arg.key] = arg.value;
                            } else if (arg.type === 'path_param') {
                                kwargs[arg.key] = pathParams[arg.key];
                            } else if (arg.type === 'appConfig') {
                                const configArg = getObjectByPath(arg.key, appConfig);
                                kwargs[arg.key] = configArg;
                            }
                        });
                    }

                    result[source.key] = await Recycle.rpcPostV2(
                        {
                            controller: source.controller,
                            method: source.method,
                            kwargs: kwargs,
                        },
                        showLoader
                    ).catch((err) => {
                        return null;
                    });
                })
            );
        }

        return result;
    };

    const refresh = (showLoader = false) => {
        initialize(showLoader);
    };

    const widget = widgets[config.type];

    if (!widget) return <h1>No widget found: {config.type}</h1>;

    return (
        <div className={classes.container} style={style}>
            {config.refreshable ? (
                <IconButton
                    className={classes.refreshButton}
                    onClick={() => refresh(true)}
                    size="large"
                >
                    <RefreshIcon />
                </IconButton>
            ) : null}
            {initialized
                ? React.createElement(widget, { config, props: widgetProps, refresh })
                : null}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'contents',
        position: 'relative',
    },
    refreshButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 10,
    },
}));

export default Widget;
