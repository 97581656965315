export const consultTypeMapping = {
    occupational_health_consult_with_cof: 'Occupational Health Consult with COF',
    adhoc_occupational_health_consult_without_cof: 'Adhoc Occupational Health Consult without COF',
    primary_health_care_consult: 'Primary Health Care Consult',
    injury_on_duty_consult: 'Injury on Duty Consult',
    chronic_care_consult: 'Chronic Care Consult',
    workplace_disease_consult: 'Workplace Disease Consult',
    traveler_medical_consult: 'Traveler Medical Consult',
    care_review_consult: 'Care Review Consult',
    sick_note_submission: 'Sick Note Submission',
};
