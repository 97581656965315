import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Pill from '../../Components/Pill';
export const PatientConditionDetails = ({ patientConditions }) => {
    const classes = useStyles();
    return (
        <div className={classes.conditions}>
            {patientConditions.map((condition, idx) => {
                return (
                        <Pill
                            backgroundColor={'white'}
                            fontColor={'#8d8c8d'}
                            topBottomPadding={'5px'}
                            leftRightPadding={'10px'}
                            text={`${condition.disease} ${['Controlled', 'Uncontrolled'].includes(condition.pathway)
                                ? condition.pathway
                                : ''
                            }`}
                            customSX={{
                                border: '1px solid #8d8c8d'
                            }}
                        />
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    conditions: {
        alignSelf: 'flex-end',
        marginBottom: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
}));
