const Pill = ({backgroundColor, fontColor, leftRightPadding, topBottomPadding, text, customSX}) =>{
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '15px',
                width: 'fit-content',
                height: 'fit-content',
                fontSize: '0.8rem',
                whiteSpace: 'nowrap',
                backgroundColor: backgroundColor,
                color: fontColor,
                paddingLeft: leftRightPadding,
                paddingRight: leftRightPadding,
                paddingTop: topBottomPadding,
                paddingBottom: topBottomPadding,
                ...customSX
            }}
        >
            {text}
        </div>
    )

}

export default Pill;
