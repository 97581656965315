import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import TextboxField from './TextField';
import MultiLineTextboxField from './MultiLineTextField';
import RadioField from './RadioField';
import PhonenumberField from './PhonenumberField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import DropdownField from './DropdownFieldReactSelect';
import NumericField from './NumericField';
import NumericInputField from './NumericInputField';
import CalculatedNumericField from './CalculatedNumericField';
import ScoringDropdownField from './ScoringDropdownFieldReactSelect';
import ToggleButtonField from './ToggleButtonFieldReact';
import MultiSelectField from './MultiSelectField';
import UploadUniversalField from './UploadUniversalFileField';
import CalculatedTextField from './CalculatedTextField';
import UppyUploadField from './UppyUploadFileField';
import HPCSAField from './HPCSAField';

const InputField = (props) => {
    const form = useForm();

    useEffect(() => {
        return () => {
            form.change(props.id, undefined);
            form.resetFieldState(props.id);
        };
    }, [form, props.id]);

    switch (props.type) {
        case 'text':
            return <TextboxField {...props} />;
        case 'multi_line_text':
            return <MultiLineTextboxField {...props} />;
        case 'multi_select':
            return <MultiSelectField {...props} />;
        case 'toggle_button':
            return <ToggleButtonField {...props} />;
        case 'numeric':
            return <NumericInputField {...props} integer={false} />;
        case 'phonenumber_field':
            return <PhonenumberField {...props} />;
        case 'integer':
            return <NumericField {...props} integer={true} />;
        case 'radio':
            return <RadioField {...props} />;
        case 'drop_down':
            return <DropdownField {...props} />;
        case 'drop_down_integer_value':
            return <DropdownField {...props} />;
        case 'scoring_drop_down':
            return <ScoringDropdownField {...props} />;
        case 'date':
            return <DateField {...props} />;
        case 'datetime':
            return <DateTimeField {...props} />;
        case 'calculated_numeric':
            return <CalculatedNumericField {...props} />;
        case 'calculated_text':
            return <CalculatedTextField {...props} />;
        case 'upload':
            return <UppyUploadField {...props} />;
        case 'universal_upload':
            return <UploadUniversalField {...props} />;
        case 'hpcsa':
            return <HPCSAField {...props} />;
        default:
            return null;
    }
};

export default React.memo(InputField);
