import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';
import axios from 'axios';

const APP_API_URL = process.env.REACT_APP_API_ENDPOINT;

const validPrefixes = [
    'MP',
    'DP',
    'PS',
]
const verifyHPCSA = async (value)=>{
    return axios.post(`${APP_API_URL}/HPCSAController/verify_hpcsa_number`, {'hpcsa_number': value})
        .then((r)=>
        {
            return r.status === 200;
        }
        ).catch(()=>{
            return false;
        })
}

const HPCSAField = ({ id, name, optional, unit, description, warning }) => {
    const label = name;
    const [verificationCount, setVerificationCount] = useState(0);

    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                placeholder={label}
                optional={optional}
                unit={unit}
                // validate={async (value)=>{
                //     const prefixMatches = validPrefixes.filter((prefix)=>{
                //         return (value??"").toUpperCase().startsWith(prefix);
                //     })
                //     if(prefixMatches.length === 0 ){
                //         return "Invalid HPCSA number"
                //     }
                //     if(value.length !==9 ){
                //         return "Invalid HPCSA number"
                //     }
                //     if(verificationCount < 3) {
                //         const isValid = await verifyHPCSA(value);
                //         setVerificationCount(verificationCount+1);
                //         if (isValid) {
                //             return undefined
                //         } else {
                //
                //             return "Invalid HPCSA number"
                //         }
                //     }
                //     else {
                //         return undefined;
                //     }
                // }}
            >
                {(fieldProps) => {
                    return (
                        <TextField
                            variant="standard"
                            label={fieldProps.placeholder}
                            name={fieldProps.input.name}
                            testid={id}
                            margin="none"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment key={`${id}-Adornment`} position="end">
                                        <label>{unit}</label>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: '1rem' }}
                            autoComplete="off"
                            required={optional === false}
                        />
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default HPCSAField;
