export const absenteeismRiskMapping = {
    'High absenteeism risk': {
        label: 'High absenteeism',
        acronym: 'HA',
        color: '#d29292',
    },
    'Medium absenteeism risk': {
        label: 'Medium absenteeism',
        acronym: 'MA',
        color: '#e9bf7a',
    },
    'Low absenteeism risk': {
        label: 'Low absenteeism',
        acronym: 'LA',
        color: '#6caa9c',
    }
};
